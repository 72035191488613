<template>
  <div class="">
    <center-title :title="acountMode == 'add' ? '子账号新增' : acountMode == 'edit' ? '子账号修改' : '子账号详情'" :border="false">
    </center-title>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="162px" class="ruleForm">
      <el-form-item label="用户头像" prop="avatar">
        <el-upload style="width: 90px; height: 90px" :action="`${download}/file/formimage`" class="info-avatar"
          :disabled="acountMode == 'View'" :headers="{ token: $store.getters.userInfo.token }"
          :on-success="successUpload" :show-file-list="false">
          <el-image class="avatar row-center" :src="userAvatar || ruleForm.avatar" fit="cover">
            <div slot="error" class="image-slot" v-if="acountMode == 'add'">
              <i class="el-icon-camera"></i>
            </div>
          </el-image>
        </el-upload>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile" class="mb30">
        <el-input v-model="ruleForm.mobile" placeholder="请输入手机号码" :disabled="acountMode == 'View'"></el-input>
      </el-form-item>
      <el-form-item label="账号密码" prop="password" class="mb30" v-if="acountMode != 'View'">
        <el-input v-model="ruleForm.password" :placeholder="acountMode == 'Edit' ? '默认不修改' : '请输入账号密码'"></el-input>
      </el-form-item>
      <el-form-item label="账号昵称" prop="nickname" class="mb30">
        <el-input v-model="ruleForm.nickname" placeholder="请输入账号昵称" :disabled="acountMode == 'View'"></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" prop="true_name" class="mb30">
        <el-input v-model="ruleForm.true_name" placeholder="请输入真实姓名" :disabled="acountMode == 'View'"></el-input>
      </el-form-item>
      <el-form-item label="用户类型" prop="group_id" class="mb30">
        <el-select v-model="ruleForm.group_id" placeholder="请选择账号身份" :disabled="acountMode == 'View'">
          <el-option label="主账号" :value="1" v-if="ruleForm.group_id == 1">
          </el-option>
          <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value" >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="管控公司" prop="checkList" class="mb30" v-if="ruleForm.group_id != 2">
        <el-checkbox-group v-model="ruleForm.checkList" :disabled="acountMode == 'View'">
          <el-checkbox :label="item.id" v-for="(item, index) in companyGroupList" :key="index">{{ item.company_name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="acountMode != 'View'">
        <el-button type="primary" class="white md bg-primary" @click="submitForm('ruleForm')">
          {{ acountMode == 'add' ? '新增' : '保存修改' }}
        </el-button>
        <el-button class="reset bg-center white md" @click="resetForm('ruleForm')" v-if="acountMode != 'Edit'">重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import { vxRule } from "@/utils/validator";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {
        mobile: "", //手机号码
        password: "", //密码
        nickname: "", //昵称
        true_name: "", //真实姓名
        group_id: "", //账号身份
        avatar: '',
        company_auth_id: '',
        checkList: [],
      },
      rules: {
        mobile: vxRule(true, "Mobile", "blur", "请输入手机号码"),
        password: vxRule(true, "Password", "blur", "请输入密码"),
        nickname: vxRule(true, null, "blur", "请输入昵称"),
        true_name: vxRule(true, null, "blur", "请输入真实姓名"),
        group_id: vxRule(true, null, "change", "请选择账号身份"),
        checkList: vxRule(true, null, "change", "请选择管控公司"),
      },
      value: [],
      download: process.env.VUE_APP_DOWNLOAD_URL,
      typeList: [
        {
          label: "采购员",
          value: 2,
        },
        {
          label: "采购经理",
          value: 3,
        },
        {
          label: "财务经理",
          value: 4,
        },
      ],
      acountMode: 'add',
      userAvatar: '',
      companyGroupList: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let api = (this.acountMode == 'add' ? this.$api.addSubAccountApi : this.$api.EditSubAccountDetailApi)

          api({
            ...this.ruleForm, user_id: this.ruleForm.id,
            avatar: this.userAvatar || this.ruleForm.avatar,
            company_auth_id: this.ruleForm.group_id == 2 ? '' : this.ruleForm.checkList.join(',')
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubAccountDetail() {
      this.$api.getSubAccountDetailApi({
        user_id: this.$route.query.id,
      }).then(res => {
        this.ruleForm = res.data
        console.log(res.data, 'res.data')
        this.ruleForm.checkList = res.data.company_auth_list.map(el => {
          return el.id
        })
        console.log(this.ruleForm)
      })
    },
    successUpload(res) {
      let { code, data } = res;
      this.userAvatar = res.data.uri
    },
    getCompanyGroup() {
      this.$api.getCompanyGroupApi().then(res => {
        if (res.code == 1) {
          this.companyGroupList = res.data;
        }
      })
    }
  },
  created() {
    if (this.$route.name == 'acountEdit') {
      this.acountMode = 'Edit'
      this.rules.password = vxRule(false, "Password", "blur", "请输入密码")
    }
    if (this.$route.name == 'acountView') {
      this.acountMode = 'View'
    }
    if (this.acountMode == 'Edit' || this.acountMode == 'View') {
      this.getSubAccountDetail();
    }
    this.getCompanyGroup();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
::v-deep .image-slot {
  font-size: 28px;
  color: #ccc;
}

.el-checkbox {
  height: 48px;
  line-height: 48px;
}

.info-avatar {
  width: 90px;
  height: 90px;
  margin-right: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f7fa;
  border: 1px solid #f5f7fa;

  .avatar {
    width: 90px;
    height: 90px;
  }

  .tips {
    bottom: 0;
    height: 25px;
    left: 0;
    right: 0;
    line-height: 20px;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}

::v-deep .el-form {
  .el-select {
    width: 100%;
  }

  .el-button,
  .el-form-item__label,
  .el-input__inner {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }

  .el-button,
  .reset {
    width: 150px;
    margin: 10px auto 0;
    padding: 0;
  }

  .reset {
    margin-left: 30px;
    border: none;
    background-color: $-color-border;
  }
}

.el-select-dropdown__item {
  font-size: 16px;
  height: 44px;
  line-height: 44px;
}
</style>